<template>
    <div class="input-group">
        <b-input-group :append="getTrad('horse.horse_interne')">
            <b-form-input
                class="form-control"
                type="text"
                :disabled="!editable"
                :placeholder="$t('horse.horse_interne')"
                v-model="horse_interne"
                @keyup="$emit('update:horse_prop_interne', horse_interne)"
            />
        </b-input-group>
    </div>
</template>

<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js";

	export default {
		mixins: [HorseMixin],
		props: [
            'horse_id',
            'horse_prop_interne',
            'editable'
		],
		data () {
			return {
                horse_interne: this.horse_prop_interne
			}
		},
        watch: {
            horse_prop_interne(val) {
                this.horse_interne = val
            }
        }
	}
</script>
